@import "src/scss/module";

.header {
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
  overflow-y: scroll;
  max-height: 100vh;

  @include media-breakpoint-up(md) {
    overflow-y: initial;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__brand {
    img {
      width: 165px;
      height: 70px;
      object-fit: contain;
    
    }
  }

  :global {
    .navbar {
      &-toggler {
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 1.5em;
          height: 1.5em;

          span {
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            height: 3px;
            width: 100%;
            background-color: var(#{--bs-primary});
          }
        }
      }

      &-collapse.show {
        box-shadow: $box-shadow-sm;

        @include media-breakpoint-up(sm) {
          box-shadow: none;
        }
      }

      &-nav {
        & > .dropdown {
          & > .nav-item {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-up(lg) {
              // justify-content: space-around;
            }

            &:focus,
            &:hover {
              background-color: rgba($black, 0.025);
            }

            & > .nav-link {
              white-space: nowrap;
              padding: 1.4575rem $spacer;
            }

            & > .dropdown-toggle {
              display: flex;
              align-items: center;
              gap: $spacer * 0.5;
              justify-content: center;
              background-color: transparent !important;
              border: 0 !important;
              padding: $spacer $spacer $spacer 0;
              transition: none !important;

              &:after {
                display: none;
              }

              & > svg {
                stroke: var(#{--bs-primary}) !important;
              }
            }
          }

          & > .dropdown-menu {
            border: 1px solid $dark;
            border-radius: 0;
            margin-top: 0;
            padding: 0;

            & > .nav-item {
              display: flex;
              align-items: center;

              &:not(:first-child) {
                border-top: 1px solid rgba($white, 0.25);
              }
            }

            .nav-link {
              color: inherit;

              padding: $spacer * 0.75 $spacer * 1.5;
              white-space: nowrap;
            }
          }
        }

        & > .nav-item {
          display: flex;
          align-items: center;

          & > .nav-link {
            white-space: nowrap;
            padding: $spacer;
          }
        }
      }
    }
  }
}
